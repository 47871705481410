<template>
    <div class="p-3">
        <el-card shadow="never">
            <div class="row no-gutters">
                <div class="col">
                    <el-button type="primary" @click="showProfessionalSkillDialog(null)">添加</el-button>
                </div>
            </div>
            <el-table :data="professionalSkills" class="mt-2">
                <el-table-column prop="name" label="名称" sortable />
                <el-table-column prop="titleLevelName" label="职称等级" sortable />
                <el-table-column label="操作" fixed="right" width="100">
                    <template #default="scope">
                        <el-button :disabled="scope.row.isSystem" type="text"
                            @click="showProfessionalSkillDialog(scope.row)">修改</el-button>
                        <el-button :disabled="scope.row.isSystem" type="text"
                            :class="{'text-danger':!scope.row.isSystem}" @click="deleteProfessionalSkill(scope.row.id)">
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>

        <el-dialog :visible.sync="professionalSkillDialogVisible" title="职业技能" :close-on-click-modal="false"
            width="400px">
            <el-form>
                <el-form-item label="名称" class="form-item-required">
                    <el-input v-model="professionalSkill.name" placeholder="名称" />
                </el-form-item>
                <el-form-item label="职称等级" class="form-item-required">
                    <el-select v-model="professionalSkill.titleLevel" placeholder="职称等级">
                        <el-option v-for="titleLevel in $store.state.dataDefinitions.titleLevels" :key="titleLevel.key"
                            :value="titleLevel.key" :label="titleLevel.value" />
                    </el-select>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="primary" @click="saveProfessionalSkill">确定</el-button>
                <el-button @click="professionalSkillDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                professionalSkills: [],
                professionalSkill: {},
                professionalSkillDialogVisible: false,
            };
        },
        methods: {
            async loadProfessionalSkills() {
                let response = await this.$axios.get('/api/ProfessionalSkill/GetProfessionalSkills');
                this.professionalSkills = response.data;
            },
            showProfessionalSkillDialog(professionalSkill) {
                if (professionalSkill) {
                    this.professionalSkill = JSON.parse(JSON.stringify(professionalSkill));
                }
                else {
                    this.professionalSkill = {};
                }
                this.professionalSkillDialogVisible = true;
            },
            async saveProfessionalSkill() {
                if (this.professionalSkill.id == undefined) {
                    await this.$axios.post('/api/ProfessionalSkill/AddProfessionalSkill', {
                        name: this.professionalSkill.name,
                        titleLevel: this.professionalSkill.titleLevel,
                    });
                    this.professionalSkillDialogVisible = false;
                    this.loadProfessionalSkills();
                }
                else {
                    await this.$axios.post('/api/ProfessionalSkill/UpdateProfessionalSkill', {
                        id: this.professionalSkill.id,
                        name: this.professionalSkill.name,
                        titleLevel: this.professionalSkill.titleLevel,
                    });
                    this.professionalSkillDialogVisible = false;
                    this.loadProfessionalSkills();
                }
            },
            async deleteProfessionalSkill(id) {
                if (!confirm('确定要删除吗？')) {
                    return;
                }

                await this.$axios.post('/api/ProfessionalSkill/DeleteProfessionalSkill', { id: id });
                this.loadProfessionalSkills();
            },
        },
        created() {
            this.loadProfessionalSkills();
        },
    };
</script>